<template>
    <div id="UsageLog">
        <h1>使用记录</h1>
    </div>
</template>

<script>
    export default {
        name: "UsageLog",
        data:function(){
            return{

            }
        },
        mounted(){

        },
        methods:{

        }
    }
</script>

<style scoped lang="scss">

</style>
